import emailjs from 'emailjs-com'
import { init } from '@emailjs/browser';
init("wJVS8NI9A6FKL8obZ");
function isValidEmail(emailTxt) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(emailTxt.match(mailformat)) {
        return true;
    }
    else
    {
        return false;
    }
}
export const sendDownload3StepLinkMail = (email) => { 
    /* ... */
    if (email && isValidEmail(email)) {
        const message = `
            <p>Please click link below to download 3-Step Guide To Increase Your Valuation with Patents</p>
            <p>
                <a target="_blank" href="https://wp.patentpc.com/wp-content/uploads/2023/07/PatentPCManifesto-1.pdf">https://wp.patentpc.com/wp-content/uploads/2023/07/PatentPCManifesto-1.pdf</a>
            </p>
        `;
        // call api
        const data = {
            email: email,
            name: email,
            phone: '',
            subject: '',
            message: `This letter is sent internally, solely intended to inform the Admin. 
                Our User enter email only to down 3-Step Guide To Increase Your Valuation with Patents.`,
            site: 'patentpc.com'
        }
        fetch("https://api.trustyip.com/subcribers/send-free-consultation", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {
            alert('The download link already sent to you your inbox. Thank you!');
        })
        .catch(err => {
            alert('There is something wrong. Please try again later!');
            console.log(err);
        });
    } else {
        alert(`Please fill your valid email ${email}`);
    }
};

/**
 * 
 * @param {*} data 
 * @param {*} alertMess 
 */
export const sendEmailToSubcriber = (data, alertMess) => { 
    if (data.email && isValidEmail(data.email)) {

        // call api
        fetch("https://api.trustyip.com/subcribers/send-free-consultation", {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json"
            },
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {
            if (alertMess) {
                alert(alertMess);
            }
        })
        .catch(err => {
            alert('There is something wrong. Please try again later!');
            console.log(err);
        });
    } else {
        alert(`Please fill your valid email ${data.email}`);
    }
};