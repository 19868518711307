import React, { useEffect, useRef, useState } from "react";
import Parser from 'react-html-parser';
import PropTypes from "prop-types";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const LegalChecklistQuiz = (props) => {
  const TITLE_QUIZ = 'Startup Legal Checklist Quiz';
  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [score, setScore] = useState(0);

  const questions = [
    {
      what: 'Have you registered your business name',
      desc: `
          Ensuring your business name is unique and not already registered is crucial to avoid legal disputes
          and branding issues. According to the Small Business Administration (SBA), over 30% of startups face
          challenges due to naming conflicts. Registering your business name not only protects your brand but
          also enhances your credibility with customers and investors. <a href="https://www.usa.gov/register-business"
          target="_blank" rel="noopener">Learn more</a>
      `
    },
    {
      what: 'Have you obtained a Federal Tax ID?',
      desc: `
      Applying for an Employer Identification Number (EIN) with the IRS is essential for tax purposes and
          to open a business bank account. Approximately 70% of small businesses use their EIN for various
          legal and financial processes. An EIN also helps protect your personal social security number and
          can be beneficial when hiring employees. <a
            href="https://www.irs.gov/businesses/small-businesses-self-employed/apply-for-an-employer-identification-number-ein-online"
            target="_blank" rel="noopener">Learn more</a>
      `
    },
    {
      what: 'Have you applied for necessary licenses and permits?',
      desc: `
      Researching and obtaining required licenses and permits for your industry and location is critical to
          ensure compliance and avoid fines. About 30% of businesses fail to meet local regulations, resulting
          in penalties and operational delays. Having the proper licenses demonstrates professionalism and
          builds trust with customers and partners. <a
            href="https://www.sba.gov/business-guide/launch-your-business/apply-licenses-permits" target="_blank"
            rel="noopener">Learn more</a>
      `
    },
    {
      what: 'Have you opened a business bank account?',
      desc: `
      Separating your personal and business finances by opening a business bank account is vital for
          financial management and tax reporting. Nearly 50% of small business owners struggle with financial
          organization due to combined accounts. A dedicated business account also simplifies bookkeeping and
          enhances your professional image. <a
            href="https://www.sba.gov/business-guide/manage-your-business/handle-your-business-finances" target="_blank"
            rel="noopener">Learn more</a>
      `
    },
    {
      what: 'Have you drafted founder agreements?',
      desc: `
      Creating agreements that define roles, responsibilities, and equity splits among founders helps
          prevent disputes. Over 60% of startups encounter co-founder conflicts without proper agreements.
          These agreements provide clarity and protect the interests of all parties involved, fostering a
          healthier working relationship. <a
            href="https://www.ycombinator.com/library/6x-a-primer-on-co-founder-equity-splits" target="_blank"
            rel="noopener">Learn more</a>
      `
    },
    {
      what: 'Have you set up accounting and record-keeping?',
      desc: `
      Choosing an accounting method and setting up a system for record-keeping is crucial for tax
          compliance and financial health. According to studies, 40% of small businesses face financial
          difficulties due to poor accounting practices. Proper accounting helps you track expenses, manage
          cash flow, and make informed business decisions. <a
            href="https://www.sba.gov/business-guide/manage-your-business/manage-your-finances" target="_blank"
            rel="noopener">Learn more</a>
      `
    },
    {
      what: 'Have you protected intellectual property?',
      desc: `
      Considering trademarks, patents, and copyrights to protect your business ideas is essential.
          Intellectual property protection is vital as about 20% of startups face IP issues within the first
          year. Protecting your IP safeguards your competitive edge and ensures you can capitalize on your
          innovations. <a href="https://www.uspto.gov/trademarks" target="_blank" rel="noopener">Learn
            more</a>
      `
    },
    {
      what: 'Are you complying with employment laws?',
      desc: `
      Understanding and complying with federal and state employment laws ensures fair treatment of
          employees and avoids legal issues. Nearly 25% of small businesses face fines due to employment law
          violations. Compliance helps build a positive workplace culture and protects your business from
          costly penalties. <a href="https://www.dol.gov/general/topic/hiring" target="_blank" rel="noopener">Learn
            more</a>
      `
    },
    {
      what: 'Have you obtained business insurance?',
      desc: `
      Protecting your business with appropriate insurance coverage mitigates risks and is crucial for
          stability. About 40% of small businesses experience losses due to lack of insurance coverage.
          Business insurance provides a safety net against unforeseen events and liabilities, ensuring your
          business can recover from setbacks. <a
            href="https://www.sba.gov/business-guide/launch-your-business/get-business-insurance" target="_blank"
            rel="noopener">Learn more</a>
      `
    },
    {
      what: 'Have you prepared privacy policy and terms of service?',
      desc: `
      Drafting and publishing a privacy policy and terms of service for your website is essential to comply
          with legal requirements and build trust with users. Approximately 60% of consumers are concerned
          about how their data is used and shared online. Clear policies help protect your business and
          reassure customers about their privacy. <a
            href="https://www.ftc.gov/tips-advice/business-center/privacy-and-security/privacy-policy" target="_blank"
            rel="noopener">Learn more</a>
      `
    }
  ];

  const getAdvice = (taskNumber) => {
    const adviceList = {
      1: `
              <p><strong>Register your business name:</strong> It's crucial to ensure your business name is unique and not already registered to avoid legal disputes and branding issues. Consider conducting a thorough search through local, state, and federal databases. Utilize tools like the U.S. Patent and Trademark Office's (USPTO) database for a comprehensive check. Registering your business name also enhances your brand's credibility and protects it from being used by others. In fact, over 30% of startups face challenges due to naming conflicts.</p>
              <p><strong>Action Plan:</strong> Start by brainstorming a unique and memorable name. Conduct searches in the USPTO database, domain name registries, and local business registration databases. Once confirmed, register the name with your state government and secure a matching domain name.</p>
          `,
      2: `
              <p><strong>Obtain a Federal Tax ID:</strong> An Employer Identification Number (EIN) is essential for tax purposes, opening a business bank account, and hiring employees. It serves as your business's social security number and is used in many legal and financial transactions. Approximately 70% of small businesses use their EIN for various processes. An EIN also helps protect your personal social security number from being used in business matters.</p>
              <p><strong>Action Plan:</strong> Apply for an EIN through the IRS website. The process is straightforward and free of charge. Have your business formation documents and personal information ready. Once obtained, use your EIN for all business-related financial activities.</p>
          `,
      3: `
              <p><strong>Apply for necessary licenses and permits:</strong> Compliance with local, state, and federal regulations is critical to avoid fines and operational delays. About 30% of businesses fail to meet local regulations, resulting in penalties. Licenses and permits vary by industry and location, so it's essential to research and understand what applies to your business.</p>
              <p><strong>Action Plan:</strong> Identify the specific licenses and permits required for your business. Visit your local city hall or county clerk's office for guidance. Use online resources from the SBA or your state's business portal. Apply for and maintain all necessary licenses and permits to ensure compliance.</p>
          `,
      4: `
              <p><strong>Open a business bank account:</strong> Separating your personal and business finances is vital for accurate financial management and tax reporting. Nearly 50% of small business owners struggle with financial organization due to combined accounts. A business bank account simplifies bookkeeping, enhances your professional image, and helps track your business’s financial health.</p>
              <p><strong>Action Plan:</strong> Choose a bank that offers business banking services tailored to your needs. Prepare necessary documents like your EIN, business formation documents, and personal identification. Open a dedicated business account and use it exclusively for business transactions.</p>
          `,
      5: `
              <p><strong>Draft founder agreements:</strong> Clear agreements among founders help prevent disputes and outline roles, responsibilities, and equity splits. Over 60% of startups encounter co-founder conflicts without proper agreements. These documents set expectations and protect the interests of all parties involved.</p>
              <p><strong>Action Plan:</strong> Work with a legal professional to draft comprehensive founder agreements. Include terms for roles, responsibilities, equity distribution, decision-making processes, and exit strategies. Ensure all founders understand and agree to the terms before signing.</p>
          `,
      6: `
              <p><strong>Set up accounting and record-keeping:</strong> Proper accounting is crucial for tax compliance and financial health. According to studies, 40% of small businesses face financial difficulties due to poor accounting practices. Implementing a solid accounting system helps you track expenses, manage cash flow, and make informed business decisions.</p>
              <p><strong>Action Plan:</strong> Choose an accounting software that suits your business needs. Set up a consistent record-keeping system for tracking income, expenses, and financial transactions. Consider hiring an accountant or bookkeeper for professional assistance.</p>
          `,
      7: `
              <p><strong>Protect intellectual property:</strong> Protecting your business ideas through trademarks, patents, and copyrights is essential. Intellectual property protection ensures your innovations remain your own and prevents others from exploiting them. About 20% of startups face IP issues within the first year.</p>
              <p><strong>Action Plan:</strong> Identify the intellectual property assets you need to protect. Consult with an IP attorney to determine the best protection strategy. File for trademarks, patents, and copyrights as needed. Regularly monitor and enforce your IP rights.</p>
          `,
      8: `
              <p><strong>Comply with employment laws:</strong> Understanding and adhering to federal and state employment laws is crucial for fair employee treatment and avoiding legal issues. Nearly 25% of small businesses face fines due to employment law violations. Compliance helps build a positive workplace culture and protects your business from costly penalties.</p>
              <p><strong>Action Plan:</strong> Familiarize yourself with employment laws relevant to your business. Implement policies and procedures that ensure compliance with hiring, wages, workplace safety, and anti-discrimination laws. Regularly review and update your employment practices to stay current with changes in the law.</p>
          `,
      9: `
              <p><strong>Obtain business insurance:</strong> Appropriate insurance coverage mitigates risks and provides stability for your business. About 40% of small businesses experience losses due to lack of insurance coverage. Business insurance protects against unforeseen events and liabilities, ensuring your business can recover from setbacks.</p>
              <p><strong>Action Plan:</strong> Assess the specific risks associated with your business. Consult with an insurance agent to identify suitable coverage options, such as general liability, property, and professional liability insurance. Regularly review your policies to ensure adequate coverage as your business grows.</p>
          `,
      10: `
              <p><strong>Prepare privacy policy and terms of service:</strong> Drafting and publishing these documents is essential for legal compliance and building trust with users. Approximately 60% of consumers are concerned about how their data is used and shared online. Clear policies help protect your business and reassure customers about their privacy.</p>
              <p><strong>Action Plan:</strong> Work with a legal professional to draft comprehensive privacy policies and terms of service. Ensure these documents are easily accessible on your website. Regularly review and update them to reflect changes in your business practices or legal requirements.</p>
          `
    };
    return adviceList[taskNumber];
  }

  const tasks = [
    "Register your business name",
    "Obtain a Federal Tax ID",
    "Apply for necessary licenses and permits",
    "Open a business bank account",
    "Draft founder agreements",
    "Set up accounting and record-keeping",
    "Protect intellectual property",
    "Comply with employment laws",
    "Obtain business insurance",
    "Prepare privacy policy and terms of service"
  ];

  const getAvatar = (score) => {
    if (score >= 8) {
      return "Startup Mastermind";
    } else if (score >= 5) {
      return "Aspiring Entrepreneur";
    } else {
      return "Newbie Founder";
    }
  }

  const getAvatarText = (avatar) => {
    const avatarText = {
      "Startup Mastermind": "Congratulations! You have a solid grasp on the essentials of starting a business. Keep up the great work!",
      "Aspiring Entrepreneur": "You're on the right track! There are a few areas to work on, but you're making good progress.",
      "Newbie Founder": "Don't worry, every expert was once a beginner. Take the time to work on these key areas, and you'll get there!"
    };
    return avatarText[avatar];
  }

  const answerQuestion = (q, a) => {
    setAnswers([...  answers, a]);
    if (a) {
      setScore(score + 1);
    }
    //
    if ((q + 1) >= questions.length) {
      // processResult();
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }

  const processResult = () => {
    const resultDiv = document.getElementById('result');
    const resultList = document.getElementById('result-list');
    resultList.innerHTML = '';

    //
    tasks.forEach((task, index) => {
      const taskNumber = index;
      const isCompleted = answers[taskNumber];
      const listItem = document.createElement('li');
      listItem.classList.add(isCompleted ? 'completed' : 'incomplete', 'result-card');
      listItem.innerHTML = `<h3>${taskNumber + 1}. ${task}</h3>`;

      if (isCompleted) {
        listItem.innerHTML += "<p>Great, you have this figured out.</p>";
      } else {
        const advice = document.createElement('div');
        advice.innerHTML = getAdvice(taskNumber);
        listItem.appendChild(advice);
      }

      resultList.appendChild(listItem);
    });
    const avatar = getAvatar(score);
    const avatarMessage = document.createElement('p');
    avatarMessage.innerHTML = `Your avatar is: <strong>${avatar}</strong><br>${getAvatarText(avatar)}`;
    resultList.appendChild(avatarMessage);

    console.log(score, avatar)

    resultDiv.style.display = 'block';
    document.getElementById('quiz').style.display = 'none';
  }


  const shareOnTwitter = () => {
    const url = window.location.href;
    const text = `I just took the Startup Legal Checklist Quiz and my avatar is: ${getAvatar(score)}. Check it out here: ${url}`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
    window.open(twitterUrl, '_blank');
  }

  const shareOnFacebook = () => {
    const url = window.location.href;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookUrl, '_blank');
  }

  const shareOnLinkedIn = () => {
    const url = window.location.href;
    const text = `I just took the Startup Legal Checklist Quiz and my avatar is: ${getAvatar(score)}. Check it out here: ${url}`;
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}&summary=${encodeURIComponent(text)}`;
    window.open(linkedInUrl, '_blank');
  }

  const shareByEmail = () => {
    const subject = 'Check out my Startup Legal Checklist Quiz Results!';
    const body = `I just took the Startup Legal Checklist Quiz and my avatar is: ${getAvatar(score)}. Check it out here: ${window.location.href}`;
    const mailtoUrl = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  }

  const restartQuiz = () => {
    setScore(0);
    setAnswers([]);
    setCurrentIndex(0);
    //
    document.getElementById('result').style.display = 'none';
    document.getElementById('quiz').style.display = 'block';
  }

  useEffect(() => {
    if (answers.length === 10) {
      processResult();
    }
  }, [answers])

  return (
    <>
      <div className="shadow" style={{ marginBottom: '50px', padding: '50px 10px' }}>

        <div className="row">
          <div className="col-md-12">
            <h2 className="text-center">{TITLE_QUIZ}</h2>
          </div>
        </div>

        <div id="quiz" className="row">
          <div className="col-md-12">
            <div class="question" data-question="1">
              <h3>{currentIndex + 1}. {questions[currentIndex].what}?</h3>
              <p>
                {Parser(questions[currentIndex].desc)}
              </p>

              <div class="actions" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <button onClick={e => answerQuestion(currentIndex, false)} style={{ fontSize: '1.1rem' }} className="btn btn-secondary">No</button>
                <button onClick={e => {answerQuestion(currentIndex, true)}} style={{ fontSize: '1.1rem' }} className="btn btn-warning">Yes</button>
              </div>
            </div>
          </div>

        </div>

        <div id="result" class="result" style={{display: 'none'}}>
          <h2 className="text-center">Quiz Results</h2>
          <ul id="result-list" style={{ listStyleType: 'none'}}></ul>
          <p id="avatar-message"></p>
          <div class="share-buttons" style={{ paddingLeft: '2rem'}}>
            <button className="btn btn-warning" onClick={shareOnTwitter}>Share on Twitter</button>
            <button className="btn btn-warning" onClick={shareOnFacebook}>Share on Facebook</button>
            <button className="btn btn-warning" onClick={shareOnLinkedIn}>Share on LinkedIn</button>
            <button className="btn btn-warning" onClick={shareByEmail}>Share via Email</button>
            <button  className="btn btn-secondary" onClick={restartQuiz}>Restart Quiz</button>
          </div>
          
        </div>

      </div>
    </>
  )
}

export default LegalChecklistQuiz