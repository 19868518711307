import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Form from 'react-bootstrap/Form';
import Parser from 'react-html-parser';
import LTVCalculatorQuiz from '../components/LTVCalculatorQuiz';
import LegalChecklistQuiz from '../components/LegalChecklistQuiz';
import CustomPopup from '../components/Popup/custom-popup';
import SideBar from '../components/SideBar/SideBar';
import Layout from '../components/layout';
import Seo from "../components/seo";
import { submitActiveCampaignForm } from '../utils/campaign';
import { sendDownload3StepLinkMail, sendEmailToSubcriber } from '../utils/react-mail-bundle';
import { getSubTitle, inventedBy, isHasAbpostIndex, isSSR, softwareTechnologyFor, updateBlogContent } from '../utils/utils';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Post({ data, description, lang, meta, title, pageContext }) {
  const PATENT_REVIEW_TAG = 'Patent Review';
  // const navigate = useNavigate();
  const childRef = useRef();
  const [visibility, setVisibility] = React.useState(false);
  const [email, setEmail] = useState('');
  //
  const allBlog = data.THE_POST;
  const tags = allBlog.tags.nodes.map(t => t.name);
  // Shuffle array
  const randomPosts = data.RANDOM_POSTS.nodes.sort(() => 0.5 - Math.random());
  // Get sub-array of first n elements after shuffled
  let selectedRandomPosts = randomPosts.slice(0, 5);
  // console.log('data >> ', allBlog)
  const [updatedBlogContent, setUpdatedBlogContent] = useState([]);
  //
  useEffect(() => {

    if (tags && tags.includes(PATENT_REVIEW_TAG)) {
      window.location.href = '/';
    }

    //
    setUpdatedBlogContent(updateBlogContent(allBlog, selectedRandomPosts));
    //
    window.sendDownloadLinkMail = () => {
      const email = document.getElementById('yourEmail').value;
      const data = { name: email, email: email };
      submitActiveCampaignForm(data, false);
      //
      sendDownload3StepLinkMail(email);
    }

    //
    window.downloadOnSideBar = () => {
      const email = document.getElementById('sideBarEmail').value;
      const data = { name: email, email: email };
      submitActiveCampaignForm(data);
    }

    //
    window.openPopupDownload = () => {
      childRef.current?.open();
    }

    // load OptinMonster
    if (window.om210904_195537) {
      window.om210904_195537.reset();
    }

    // 
    document.addEventListener('om.Campaign.parseCampaigns', function (event) {
      const email = document.getElementById('eminence-field-email').value;
      // submit campaign
      if (email) {
        const data = { name: email, email: email };
        submitActiveCampaignForm(data, false);
      }
    });

  }, []);

  //
  const handleGeneratePdf = async () => {
    const jsPDF = (await import('jspdf')).default;
    const doc = new jsPDF({
      format: "a4",
      unit: "px"
    });

    doc.html(document.getElementById('blogContent'), {
      async callback(doc) {
        await doc.save(allBlog.slug + ".pdf");
      },
      html2canvas: { scale: 0.5, imageTimeout: 0, svgRendering: true, useCORS: true },
      image: { type: "jpeg", quality: 0.5 },
      retina: true,
      pagesplit: true,
      enableLinks: false,
      autoPaging: 'text',
      margin: [50, 50, 20, 50]
    });
  };
  //
  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const submitDownloadPdf = (e, showAlert = false) => {
    e?.preventDefault();
    if (email) {
      const emailVal = email;
      // generate pdf
      if (!showAlert) {
        handleGeneratePdf();
      }
      // submit campagin
      submitActiveCampaignForm({ name: emailVal, email: emailVal }, showAlert);

      // subscriber add
      // const subcriberData = {
      //   email: emailVal,
      //   name: emailVal,
      //   phone: '',
      //   subject: 'Thank you for subscribing to PatentPC.com',
      //   message: ``,
      //   site: 'patentpc.com'
      // }

      // sendEmailToSubcriber(subcriberData, 'Thank you for subscribing to PatentPC.com');

      // close popup
      childRef?.current?.close();
    } else {
      alert('Please fill in all fields.');
    }
  }

  // get h2 sub title
  const h2SubTitle = getSubTitle(allBlog.content);

  // check abpost-index
  const isAbpostIndex = isHasAbpostIndex(allBlog.content);

  //
  return (
    <>
    {
      tags && tags.includes(PATENT_REVIEW_TAG) ?
      (isSSR() ? '' : <script>window.location.href = '/';</script>)
      :
      <Layout>
        <Seo title={`${allBlog.title}`} canonical={`/blog${allBlog.uri.slice(0, -1)} `} seo={allBlog.seo} />
        <section className="mainSpacing">
          <div className="container px-0">
            <div className="row">
              <div className="col-lg-9 col-sm-12 word-wrap" id="blogContent">
                <article className="mb-5 text-center">
                  <h1 className="card-title mb-2">
                    {softwareTechnologyFor(allBlog.title)}
                  </h1>
                  <p>---</p>
                  {h2SubTitle ? <h2 className="h5">{inventedBy(h2SubTitle)}</h2> : null}
                  <small>{allBlog.date}</small>
                  {!isAbpostIndex ?
                    <div class="abpost-index"><div class="px-5 py-3"><strong>Bonus:</strong> <a class="text-danger text-decoration-none fw-bold" href="javascript: openPopupDownload();">Download the entire article,</a>
                      plus exclusive action-items that will show you how to implement the tactics and tips written about in this article.</div></div>
                    : null
                  }
                </article>
                {
                  allBlog.id === 'cG9zdDo3MTY5Mw==' &&
                  <div>
                    <LegalChecklistQuiz></LegalChecklistQuiz>
                  </div>
                }

                {
                  allBlog.id === 'cG9zdDo3MTcxNg==' &&
                  <div>
                    <LTVCalculatorQuiz></LTVCalculatorQuiz>
                  </div>
                }

                <div id="postContentDiv" className="card-text post-content">{Parser(isSSR() ? allBlog.content : updatedBlogContent)}</div>
              </div>
              <div className="col-lg-3 col-sm-12">
                <SideBar></SideBar>
              </div>
            </div>
          </div>
        </section>
        <CustomPopup
          onClose={popupCloseHandler}
          ref={childRef}
          show={false}
          title="">
          <h3 className="text-center fw-bold mb-4">Submit your email and get to <br /> download  this post as a PDF file</h3>
          <Form >
            <Form.Group className="mb-3" controlId="formBasicEmail" value={email} onChange={e => setEmail(e.target.value)}>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>

            <button className='btn btn-warning w-100' onClick={submitDownloadPdf}>Submit</button>
          </Form>
        </CustomPopup>
      </Layout>
    }
    </>
  )
}

Post.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Post.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export const query = graphql`
  query($slug: String) {
    RANDOM_POSTS: allWpPost(
      limit: 5, 
      filter: {tags: {nodes: {elemMatch: {name: {ne: "Patent Review"}}}}},
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        title
        slug
      }
    }
    THE_POST: wpPost(slug: { eq: $slug }) {
      seo {
        canonical
        focuskw
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
      date(formatString: "LL")
      content
      id
      uri
      slug
      title
      categories {
        nodes {
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }`
export default Post;
