import React, { useEffect, useRef, useState } from "react";
import Parser from 'react-html-parser';
import PropTypes from "prop-types";
import "../styles/style-ltv.css";
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
const LTVCalculatorQuiz = (props) => {
  const TITLE_QUIZ = 'Customer Lifetime Value (LTV) Calculator Quiz';

  function showNextQuestion(current, next) {
    document.getElementById('question' + current).classList.remove('active');
    document.getElementById('question' + next).classList.add('active');
    updateProgressBar(next);
  }

  function updateProgressBar(questionNumber) {
    const totalQuestions = 3;
    const progress = (questionNumber / totalQuestions) * 100;
    document.getElementById('progress-bar-fill').style.width = progress + '%';
  }

  function calculateLTV() {
    document.getElementById('result').style.display = 'block';
    //
    const averagePurchaseValue = parseFloat(document.getElementById('averagePurchaseValue').value);
    const averagePurchaseFrequency = parseFloat(document.getElementById('averagePurchaseFrequency').value);
    const customerLifespan = parseFloat(document.getElementById('customerLifespan').value);

    if (isNaN(averagePurchaseValue) || isNaN(averagePurchaseFrequency) || isNaN(customerLifespan)) {
      document.getElementById('result').innerHTML = '<span>Please provide valid inputs for all questions.</span> <a href="#" onclick="restartQuiz()">Restart Quiz</a>';
      return;
    }

    const customerLifetimeValue = averagePurchaseValue * averagePurchaseFrequency * customerLifespan;

    document.getElementById('result').innerHTML = `
        <h3>LTV Analysis Results</h3>
        <p>Your customer lifetime value (LTV) is <strong>$${customerLifetimeValue.toFixed(2)}</strong>.</p>
        <p>Actionable Insights:</p>
        <ul>
            <li>Compare your LTV to your customer acquisition cost (CAC) to ensure profitability. A good rule of thumb is that your LTV should be at least 3 times your CAC.</li>
            <li>Increase your LTV by improving customer retention: Implement loyalty programs, enhance customer service, and ensure consistent communication with your customers.</li>
            <li>Boost the average purchase value: Offer upsells, cross-sells, and product bundles to encourage higher spending per transaction.</li>
            <li>Enhance purchase frequency: Use email marketing, special promotions, and subscription models to encourage repeat purchases.</li>
        </ul>
        <p>Powered by <a href="https://patentpc.com" target="_blank">PatentPC. Schedule a free IP Strategy session</a> with our expert attorneys today!</p>
    `;
  }

  useEffect(() => {
    window.restartQuiz = function () {
      document.getElementById('result').style.display = 'none';
      //
      for (let i = 1; i < 4; i++) {
        document.getElementById('question' + i).classList.remove('active');
      }
      // show 1
      document.getElementById('question1').classList.add('active');
      updateProgressBar(0)
    }
  }, [])

  return (
    <>
    <div className="ltv-cal-quiz">
      <div className="shadow" style={{ marginBottom: '50px', padding: '50px 10px' }}>
        <div className="row">
          <div className="col-md-12">
            <h2>{ TITLE_QUIZ }</h2>
            <div class="progress-bar">
              <div class="progress-bar-fill" id="progress-bar-fill"></div>
            </div>
            <form id="ltvForm">
              <div class="question active" id="question1">
                <label for="averagePurchaseValue">1. What is the average amount a customer spends per purchase? (in $)</label>
                <input type="number" id="averagePurchaseValue" name="averagePurchaseValue" placeholder="e.g., $50" required />
                <button type="button" onClick={e => showNextQuestion(1, 2)}>Next</button>
              </div>
              <div class="question" id="question2">
                <label for="averagePurchaseFrequency">2. How many times does a typical customer make a purchase in a month?</label>
                <input type="number" id="averagePurchaseFrequency" name="averagePurchaseFrequency" placeholder="e.g., 2 times" required />
                <button type="button" onClick={e => showNextQuestion(2, 3)}>Next</button>
              </div>
              <div class="question" id="question3">
                <label for="customerLifespan">3. How many months, on average, does a customer continue to buy from you?</label>
                <input type="number" id="customerLifespan" name="customerLifespan" placeholder="e.g., 24 months" required />
                <button type="button" onClick={calculateLTV}>Submit</button>
              </div>
            </form>
            <div id="result" style={{ display: 'none' }}></div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default LTVCalculatorQuiz;
